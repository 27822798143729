import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import {
  MdPhoneIphone,
  MdEmail,
  MdLocalPhone,
  MdLocationOn,
} from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import { PageTitle } from '../components/shared/styled/headings';
import { Content, Box, FormWrapper } from '../components/shared/styled/layout';
import { ExternalLink } from '../components/shared/styled/a';
import theme from '../config/theme';

const centerTd = {
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
};

const PageNotreRegion = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact - Gîtes en Pyrénées Cathares</title>
        <meta
          name="description"
          content={`Contactez-nous par téléphone, mail ou le formulaire de contact.`}
        />
      </Helmet>
      <Content style={{ paddingTop: '3rem', maxWidth: '700px' }}>
        <PageTitle>Contactez nous</PageTitle>
        <Box>
          <p>Béatrice and Gilbert BERTRAND</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <div style={centerTd}>
                    <MdLocationOn title="Adresse" />
                  </div>
                </td>
                <td>Pays d{"'"}olmes, La Coume, 09300 Montferrier</td>
              </tr>
              <tr>
                <td>
                  <div style={centerTd}>
                    <MdPhoneIphone title="Téléphone portable" />
                  </div>
                </td>
                <td>
                  <ExternalLink href="tel:0684390935" text="0684390935" />
                </td>
              </tr>
              <tr>
                <td>
                  <div style={centerTd}>
                    <MdLocalPhone title="Téléphone fixe" />
                  </div>
                </td>
                <td>
                  <ExternalLink href="tel:0561011853" text="0561011853" />
                </td>
              </tr>
              <tr>
                <td>
                  <div style={centerTd}>
                    <MdEmail title="Email" />
                  </div>
                </td>
                <td>
                  <ExternalLink
                    href="mailto:gilbert.bertrand@sfr.fr"
                    text="gilbert.bertrand@sfr.fr"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        <FormWrapper
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BsCheckCircle
            size="30"
            color={theme.colors.green}
            title="Message envoyé"
            style={{ marginRight: '0.5rem' }}
          />
          <span>Votre message a bien été envoyé. Merci.</span>
        </FormWrapper>
      </Content>
    </Layout>
  );
};

export default PageNotreRegion;
